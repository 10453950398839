import React from 'react';
import ReactGA from 'react-ga4';
import { blogPosts } from './components/data';
import { Box, Container, Link, Typography } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation, Link as RouterLink } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import RssFeedIcon from '@mui/icons-material/RssFeed';

import logo from './media/logo.webp';
import macFolder from './media/MacFolder.webp';
import "./AppOutput.css";
import Timeline from "./components/Timeline";
import MarkdownPost from "./components/MarkdownPost";
import RSSFeed from "./components/RSSFeed";
import IncidentCounter from "./components/IncidentCounter";
import EveryDay from "./components/EveryDay";
import YearlyInterview from "./yearly_interview/YearlyInterview";
import './utils/fetchInterceptor';
import dailyGif from './media/gif_daily.gif';
import SavedSearch from "./saved_search/SavedSearch";
import PoetryGame from "./poetry_game/PoetryGame";

// Initialize GA with your Measurement ID
ReactGA.initialize('G-0LQLNLFQSF');

const TimelinePage = () => {
    console.log("TimelinePage rendering", blogPosts);
    return (
        <div className="page-wrapper">
            <div className="hero-section">
                <img
                    className="logo-animated"
                    src={logo}
                    alt="Logo"
                />
            </div>

            <div className="content-section">
                {/* Projects Section */}

                <div className="section-projects">
                    <h2 className="section-title">Projects</h2>
                    <div className="projects-grid">
                        {/* <RouterLink to="/daily/chisler" className="project-card">
                            <div className="card-content">
                                <div className="card-header">
                                    <h3>Daily Working Face</h3>
                                    <div className="post-icon">
                                        <img 
                                            src={dailyGif}
                                            alt="Daily Work"
                                        />
                                    </div>
                                </div>
                                <p>1hr of work, every day. Turned into a GIF.</p>
                            </div>
                        </RouterLink> */}

                        {/* <RouterLink to="/saved-search" className="project-card">
                            <div className="card-content">
                                <div className="card-header">
                                    <h3>Ebay Saved Search</h3>
                                    <div className="post-icon">
                                        <img 
                                            src={macFolder}
                                            alt="Saved Search"
                                        />
                                        Search Creator
                                    </div>
                                </div>
                                <p>"Find things Aaron Levine wears".</p>
                            </div>
                        </RouterLink> */}
                        

                        <RouterLink to="/yearly-interview" className="project-card">
                            <div className="card-content">
                                <div className="card-header">
                                    <h3>Your Interview Archive</h3>
                                    <div className="post-icon">
                                        <img 
                                            src={macFolder}
                                            alt="Yearly Interview"
                                        />
                                        The Interview Archive
                                    </div>
                                </div>
                                <p>Record a video interview with yourself every year.</p>
                            </div>
                        </RouterLink>
                    </div>
                </div>

                {/* Blog Posts Section */}
                <div className="section-blog">
                    <h2 className="section-title">Blog</h2>
                    <Timeline blogPosts={blogPosts} />
                </div>
            </div>

            <footer className="footer">
                <div className="social-links">
                    <a href="https://www.instagram.com/a.chisler/"
                        target="_blank"
                        className="social-link">
                        instagram
                    </a>
                    <span className="dot">·</span>
                    <a href="https://twitter.com/alex_chisler"
                        target="_blank"
                        className="social-link">
                        twitter
                    </a>
                    <span className="dot">·</span>
                    <a href="/rss.xml"
                        target="_blank"
                        className="social-link">
                        rss
                    </a>
                </div>
            </footer>
        </div>
    );
}

// This component tracks route changes
function RouteTracker() {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
}

//App that allows to access blog routes by slug
//and stores the blog list at index
function App() {
    return (
        <Router>
            <RouteTracker />
            <div className="App">
                <Routes>
                    <Route path="/" element={<TimelinePage />} />
                    <Route path="/incident-counter" element={<IncidentCounter />} />
                    <Route path="/posts/:slug" element={<MarkdownPost />} />
                    <Route path="/rss.xml" element={<RSSFeed />} />
                    <Route path="/daily" element={<EveryDay />} />
                    <Route path="/daily/:username" element={<EveryDay />} />
                    <Route path="/yearly-interview" element={<YearlyInterview />} />
                    <Route path="/saved-search" element={<SavedSearch />} />
                    <Route path="/poetry-game" element={<PoetryGame />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
