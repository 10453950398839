import React, { useState } from 'react';
import './SavedSearch.css';

const translations = {
  en: {
    title: "Ebay Saved Search",
    subtitle: "Transform natural language into precise eBay searches",
    searchPlaceholder: "e.g. 'Brands that Aaron Levine wears' or 'Japanese workwear brands'",
    searchButton: "Generate Search",
    loading: "Generating search...",
    errorMessage: "Failed to generate search. Please try again.",
  },
  ru: {
    title: "Создатель Сохраненного Поиска",
    subtitle: "Преобразуйте обычный текст в точные поисковые запросы eBay",
    searchPlaceholder: "например, 'Бренды, которые носит Aaron Levine' или 'Японские рабочие бренды'",
    searchButton: "Создать Поиск",
    loading: "Создание поиска...",
    errorMessage: "Не удалось создать поиск. Пожалуйста, попробуйте снова.",
  }
};

export default function SavedSearch() {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('en');
  const [excludedBrands, setExcludedBrands] = useState(new Set());

  // Get current translations
  const currentTranslations = translations[language];

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setResult(null);

    // if locally, use localhost:8001

  const API_URL = window.location.hostname.includes("sashachisler")
  ? 'https://api.supervision-ai.com'
  : 'http://127.0.0.1:8000';

    try {
      const response = await fetch(`${API_URL}/search/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        throw new Error('API request failed');
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError(currentTranslations.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const buildEbayQuery = () => {
    if (!result?.brands) return '';
    const includedBrands = result.brands
      .split(',')
      .map(brand => brand.trim())
      .filter(brand => !excludedBrands.has(brand))
      .join(',');
    return `https://www.ebay.com/sch/i.html?_nkw=(${encodeURIComponent(includedBrands)})`;
  };

  return (
    <div className="saved-search">
      <div className="search-container">
        <div className="left-section">
          <header className="search-header">
            <h1>{currentTranslations.title}</h1>
            <p className="subtitle">{currentTranslations.subtitle}</p>
          </header>

          <div className="search-form">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={currentTranslations.searchPlaceholder}
              className="search-input"
            />
            <button 
              className="primary-button"
              onClick={handleSearch}
              disabled={loading || !query.trim()}
            >
              {loading ? currentTranslations.loading : currentTranslations.searchButton}
            </button>
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          {result && (
            <div className="search-result">
              <div className="result-query">
                <h3>eBay Search</h3>
                <p>{result.ebayQuery}</p>
                <a 
                  href={buildEbayQuery()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ebay-link"
                >
                  View on eBay →
                </a>
              </div>

              <div className="brand-exclusions">
                <h3>Exclude Brands</h3>
                <div className="brand-checkboxes">
                  {result.brands.split(',').map(brand => {
                    const trimmedBrand = brand.trim();
                    return (
                      <label key={trimmedBrand} className="brand-checkbox">
                        <input
                          type="checkbox"
                          checked={excludedBrands.has(trimmedBrand)}
                          onChange={(e) => {
                            const newExcludedBrands = new Set(excludedBrands);
                            if (e.target.checked) {
                              newExcludedBrands.add(trimmedBrand);
                            } else {
                              newExcludedBrands.delete(trimmedBrand);
                            }
                            setExcludedBrands(newExcludedBrands);
                          }}
                        />
                        {trimmedBrand}
                      </label>
                    );
                  })}
                </div>
              </div>

              <div className="result-explanation">
                <h3>Search Explanation</h3>
                <p>{result.explanation}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 