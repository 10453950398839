import React, { useState, useEffect } from 'react';
import './PoetryGame.css';
import maxPhoto from './img/max1.jpg';
import IdeaDialog from './IdeaDialog';
import AuthorSelectDialog from './AuthorSelectDialog';

// (These texts and API endpoints are from the original page)
const TEXTS = {
  title: "Песня-чепуха для Максима 🎂",
  subtitle: "Давайте вместе напишем прайкольное поздравление на день рождения!",
  lastLine: "Последняя строчка",
  firstLineLabel: "Продолжите строчку (в рифму)",
  secondLineLabel: "Добавьте новую строчку (с новой рифмой)",
  authorLabel: "Ваше ФИО (мне надо вас будет найти)",
  submitButton: "Добавить поздравление",
  submittingButton: "Отправляем...",
  loadingButton: "Загружаем...",
  viewPoemButton: "Посмотреть всё стихотворение, или оставьте как сюрприз",
  previewMessage: "Начни творить! ✍️",
  loadingMessage: "Загружаем стихотворение...",
  loadingLastLine: "Загружаем последние строчки...",
  viewPoemDisabled: "Стихотворение можно будет посмотреть с 15 марта 2025",
  showIdea: "FAQ: Как будет сделана песня? 🤔",
  findMyLines: "Подсмотреть свои строчки 👀",
  yourLines: "Твои строчки:",
};

const API_URL =
  "https://script.google.com/macros/s/AKfycbzeKZgx7l80_W0QyDv90UwHhueff3OeLnpwsRn0IiiXePbpIDBhIRbg1yRi4JvAbdrW/exec";

const api = {
  getAllVerses: async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) throw new Error("Не удалось загрузить данные");
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      throw error;
    }
  },
  addVerse: async (verseData) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "text/plain;charset=utf-8" },
        body: JSON.stringify(verseData),
      });
      if (!response.ok) throw new Error("Не удалось сохранить строфу");
      const result = await response.json();
      return result.data;
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
      throw error;
    }
  },
};

// --- The original interactive poetry game component ---
function OriginalPoetryGame() {
  const [lastVerse, setLastVerse] = useState(null);
  const [formData, setFormData] = useState({ firstLine: '', secondLine: '', author: '' });
  const [verses, setVerses] = useState([]);
  const [showFullPoem, setShowFullPoem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState("");
  const [showIdeaDialog, setShowIdeaDialog] = useState(false);
  const [showAuthorDialog, setShowAuthorDialog] = useState(false);

  useEffect(() => {
    const fetchVerses = async () => {
      setIsLoading(true);
      try {
        const allVerses = await api.getAllVerses();
        setVerses(allVerses);
        if (allVerses.length > 0) {
          const last = allVerses[allVerses.length - 1];
          setLastVerse({ secondLine: last.secondLine, author: last.author });
        }
      } catch (err) {
        setError("Ошибка при загрузке данных.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchVerses();
  }, []);

  const handleChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.addVerse(formData);
      const newVerse = { ...formData, timestamp: new Date().toISOString() };
      setVerses(prev => [...prev, newVerse]);
      setLastVerse({ secondLine: formData.secondLine, author: formData.author });
      setFormData({ firstLine: '', secondLine: '', author: '' });
      setToast("🎉 Отлично, теперь передай другому! 🎉");
      setTimeout(() => setToast(""), 3000);
    } catch (err) {
      setError("Ошибка при сохранении.");
    } finally {
      setIsLoading(false);
    }
  };

  const canViewPoem = () => {
    const viewDate = new Date('2025-03-15');
    return new Date() >= viewDate;
  };

  const getAuthors = () => {
    const uniqueAuthors = [...new Set(verses.map(verse => verse.author))];
    return uniqueAuthors.sort();
  };

  return (
    <div className="original-poetry-game">
      <div className="main-container">
        <IdeaDialog 
          isOpen={showIdeaDialog} 
          onClose={() => setShowIdeaDialog(false)} 
        />
        
        <header className="header">
          <h1>{TEXTS.title}</h1>
          <p>{TEXTS.subtitle}</p>
        </header>
        
        <div className="max-photo-container">
          <img src={maxPhoto} alt="Максим" />
        </div>
        
        {error && <div className="error">{error}</div>}
        <main className="content">
          <section className="poem-input">
            {lastVerse ? (
              <div className="last-verse">
                <strong>{TEXTS.lastLine} от {lastVerse.author}:</strong>{" "}
                <span>"{lastVerse.secondLine}"</span>
              </div>
            ) : isLoading ? (
              <div className="last-verse loading">
                <span className="loading-text">{TEXTS.loadingLastLine}</span>
                <div className="loading-dots"></div>
              </div>
            ) : null}
            <form onSubmit={handleSubmit}>
              <label>
                {TEXTS.firstLineLabel}:
                <input
                  type="text"
                  name="firstLine"
                  value={formData.firstLine}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                {TEXTS.secondLineLabel}:
                <input
                  type="text"
                  name="secondLine"
                  value={formData.secondLine}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                {TEXTS.authorLabel}:
                <input
                  type="text"
                  name="author"
                  value={formData.author}
                  onChange={handleChange}
                  required
                />
              </label>
              <button 
                type="submit" 
                disabled={isLoading}
                className={isLoading ? 'loading' : ''}
              >
                {isLoading ? 
                  (formData.firstLine || formData.secondLine || formData.author ? 
                    TEXTS.submittingButton : TEXTS.loadingButton) 
                  : TEXTS.submitButton}
              </button>
            </form>
            <div className="poem-controls">
              <button
                className="find-lines-button"
                onClick={() => setShowAuthorDialog(true)}
              >
                {TEXTS.findMyLines}
              </button>
              
              <button
                className={`toggle-poem ${isLoading ? 'loading' : ''}`}
                onClick={() => setShowFullPoem(prev => !prev)}
                disabled={isLoading || !canViewPoem()}
                style={{ opacity: canViewPoem() ? 1 : 0.5 }}
              >
                {canViewPoem() ? TEXTS.viewPoemButton : TEXTS.viewPoemDisabled}
              </button>
            </div>
          </section>
          
          {toast && <div className="toast">{toast}</div>}
          
          {showFullPoem && (
            <section className="poem-display">
              <h2>Собранное поздравление</h2>
              {isLoading ? (
                <div className="loading-indicator">{TEXTS.loadingMessage}</div>
              ) : verses.length === 0 ? (
                <p>{TEXTS.previewMessage}</p>
              ) : (
                <ul>
                  {verses.map((verse, idx) => (
                    <li key={idx}>
                      <p>{verse.firstLine}</p>
                      <p>{verse.secondLine}</p>
                      <p className="author">— {verse.author}</p>
                    </li>
                  ))}
                </ul>
              )}
            </section>
          )}
        </main>
        <footer className="footer">
          <button 
            className="idea-button"
            onClick={() => setShowIdeaDialog(true)}
          >
            {TEXTS.showIdea}
          </button>
          <p>С любовью, твой сайт для стихов 💌</p>
        </footer>
        
        <AuthorSelectDialog
          isOpen={showAuthorDialog}
          onClose={() => setShowAuthorDialog(false)}
          authors={getAuthors()}
          verses={verses}
        />
      </div>
    </div>
  );
}

// --- The new birthday gift page ---
export default function BirthdayGiftPage() {
  const [showOriginal, setShowOriginal] = useState(false);

  return (
    <div className="birthday-gift-page">
      <div className="confetti-container">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="confetti"
            style={{
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              transform: `rotate(${Math.random() * 360}deg)`
            }}
          />
        ))}
      </div>
      {!showOriginal ? (
        // Gift page
        <div className="gift-container">
          <header className="header">
            <h1>С днем рождения, Максим! 🎂</h1>
            <p>С юбилеем! Твои друзья собрались вместе и заебашили это, чтобы ты знал, как мы тебя любим. Это для тебя! 💘 </p>
            <div className="max-photo-container">
              <img src={maxPhoto} alt="Максим" />
            </div>
          </header>
          <div className="video-link">
            <a 
              className="video-button"
              href="https://www.youtube.com/watch?v=-z_YgWMWJiM&feature=youtu.be" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Смотреть подарочное видео 🎉
            </a>
          </div>
          <button 
            className="original-toggle-button" 
            onClick={() => setShowOriginal(true)}
          >
            Посмотреть как это делалось
          </button>
        </div>
      ) : (
        // Original page
        <>
          <OriginalPoetryGame />
          <button 
            className="original-toggle-button" 
            onClick={() => setShowOriginal(false)}
          >
            Вернуться к подарку
          </button>
        </>
      )}
    </div>
  );
}
