import React from 'react';
import './IdeaDialog.css';
import beatAudio from './beat.mp3';

// Иконки для шагов
const StepIcons = {
  Write: () => (
    <svg className="step-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M20 11.08V8l-6-6H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V11.08zm-9.44-1.66l2.12-2.12 4.95 4.95-2.12 2.12l-4.95-4.95zm8.49 3.54l-4.95-4.95 1.41-1.41c.78-.78 2.05-.78 2.83 0l2.12 2.12c.78.78.78 2.05 0 2.83l-1.41 1.41z"/>
    </svg>
  ),
  Video: () => (
    <svg className="step-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zM14 13h-3v3H9v-3H6v-2h3V8h2v3h3v2z"/>
    </svg>
  ),
  Music: () => (
    <svg className="step-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
    </svg>
  )
};

export default function IdeaDialog({ onClose, isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="idea-dialog-overlay">
      <div className="idea-dialog">
        <h2>Как мы сделаем песню для Максима 🎵</h2>
        
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-info">
              <h3>Пишем текст</h3>
              <p>Первая строчка — продолжение предыдущей рифмы<br/>
                 Вторая строчка — начало новой рифмы</p>
            </div>
          </div>
          
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-info">
              <h3>Снимаем видео</h3>
              <p>Запишите короткое селфи, где вы читаете свои строчки</p>
              <a href='https://drive.google.com/drive/u/0/folders/1e0QqZwmfy_96KCi82J6kaBW4LvSqn8BI' className="upload-link">
                👉 Загрузите сюда
              </a>
            </div>
          </div>
          
          <div className="step">
            <div className="step-number">3</div>
            <div className="step-info">
              <h3>Будет песня!</h3>
              <p>Смонтирую под этот бит:</p>
              <audio controls className="beat-player">
                <source src={beatAudio} type="audio/mpeg" />
              </audio>
            </div>
          </div>
        </div>

        <div className="dialog-buttons">
          <button onClick={onClose}>ПОН</button>
          <button onClick={onClose}>Жесткий пон</button>
        </div>
      </div>
    </div>
  );
} 