import React, { useState, useRef, useEffect } from 'react';
import './YearlyInterview.css';

const questions = {
  en: [
    "How was this year, from 1 to 10?",
    "What was the most delicious thing you ate this year?",
    "Who did you spend the most time with?",
    "How much time do you think you'll spend with them for the rest of your life?",
    "Who did you not have enough time for this year?",
    "What were you doing last winter?",
    "Do you love living?",
    "What did you do this year that you hadn't done before?",
    "What do you love about life?"
  ],
  ru: [
    "Как прошел этот год, от 1 до 10?",
    "Что было самое вкусное, что вы ели в этот год?",
    "С кем провели больше всего времени?",
    "Сколько времени думаете, вы проведёте с ними до конца жизни?",
    "На кого в этом году не хватало времени?",
    "Что вы делали прошлой зимой?",
    "Вы любите жить?",
    "Что в этом году вы сделали такого, чего не делали в прошлом?",
    "Что вы любите в жизни?"
  ]
};

const translations = {
  en: {
    title: "The Interview Archive",
    subtitle: "Make a video to watch in 2035.",
    recording: "Recording",
    questionCount: "Question",
    of: "of",
    startButton: "Start Your Interview",
    finishButton: "Finish & Download",
    nextButton: "Next Question",
    completeButton: "Complete Interview"
  },
  ru: {
    title: "Зэ Интервью",
    subtitle: "Видео-опросник Марселя Пруста. Интересно, как поменяются ваши ответы к 2035 году.",
    recording: "Идёт запись",
    questionCount: "Вопрос",
    of: "из",
    startButton: "Записать интервью про 2024",
    finishButton: "Завершить и скачать",
    nextButton: "Следующий вопрос",
    completeButton: "Завершить интервью"
  }
};

export default function YearlyInterview() {
  const [recording, setRecording] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestion, setShowQuestion] = useState(false);
  const [language, setLanguage] = useState('en');

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const cameraStreamRef = useRef(null);

  useEffect(() => {
    // Get language from URL query parameter
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    if (lang && ['en', 'ru'].includes(lang)) {
      setLanguage(lang);
    }
  }, []);

  // Use currentQuestions instead of directly accessing questions array
  const currentQuestions = questions[language];

  // Get current translations
  const currentTranslations = translations[language];

  // 1) Request camera + mic access, attach to hidden <video>
  useEffect(() => {
    (async () => {
      try {
        // Check for camera permissions
        // (Note: Not all browsers fully implement navigator.permissions yet)
        if (navigator.permissions && navigator.permissions.query) {
          const permissions = await navigator.permissions.query({ name: 'camera' });
          if (permissions.state === 'denied') {
            console.error('Camera permission is denied. Please enable it in your browser settings.');
            return;
          }
        }

        // Prompt for camera + microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 640 },
            height: { ideal: 480 }
          },
          audio: true,
        });
        
        cameraStreamRef.current = stream;

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play().catch((e) => {
            console.error("Error playing video:", e);
          });
        }
      } catch (err) {
        console.error("Error accessing media devices:", err);
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          console.error('Camera permission was denied by the user or system');
        }
      }
    })();

    return () => {
      // On cleanup, stop all tracks
      if (cameraStreamRef.current) {
        cameraStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // 2) Continuously draw the hidden video onto the canvas, plus question overlay if needed
  useEffect(() => {
    let fps = 30;
    let intervalId;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const video = videoRef.current;

    function drawFrame() {
      if (!video || video.readyState < 2) return;
      
      // Save context state
      ctx.save();
      
      // Mirror horizontally
      ctx.scale(-1, 1);
      ctx.translate(-canvas.width, 0);
      
      // Draw the mirrored video
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      // Restore context
      ctx.restore();

      if (showQuestion) {
        ctx.fillStyle = 'rgba(0,0,0,0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const maxWidth = canvas.width * 0.7;
        const lineHeight = 36;
        const fontSize = 24;
        
        ctx.fillStyle = '#fff';
        ctx.font = `${fontSize}px 'Inter', monospace`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';

        const text = currentQuestions[currentQuestionIndex];
        const words = text.split(' ');
        let line = '';
        let y = (canvas.height - ((words.length * lineHeight) / 2)) / 2;
        
        words.forEach((word, index) => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          
          if (metrics.width > maxWidth && line !== '') {
            ctx.fillText(line, canvas.width/2, y);
            line = word + ' ';
            y += lineHeight;
          } else {
            line = testLine;
          }
          
          if (index === words.length - 1) {
            ctx.fillText(line, canvas.width/2, y);
          }
        });
      }
    }

    intervalId = setInterval(drawFrame, 1000 / fps);

    return () => clearInterval(intervalId);
  }, [showQuestion, currentQuestionIndex, currentQuestions]);

  // 3) Start a single MediaRecorder on the canvas stream + audio from camera
  const startRecording = () => {
    if (!canvasRef.current) return;
    if (recording) return;

    recordedChunksRef.current = []; // reset

    // Capture the canvas stream at 30fps
    const canvasStream = canvasRef.current.captureStream(30);

    // Add the audio track(s) from the camera
    if (cameraStreamRef.current) {
      const audioTracks = cameraStreamRef.current.getAudioTracks();
      audioTracks.forEach(track => canvasStream.addTrack(track));
    }

    // Try MP4 first, then fallback to WebM
    const supportedTypes = [
      'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',  // MP4 with H.264 + AAC
      'video/webm; codecs=vp9,opus',                // WebM with VP9
      'video/webm; codecs=vp8,opus'                 // WebM with VP8
    ];
    const mimeType = supportedTypes.find(type => MediaRecorder.isTypeSupported(type)) || '';

    // If nothing is supported, fallback to a basic WebM
    const finalMimeType = mimeType || 'video/webm';

    const mediaRecorder = new MediaRecorder(canvasStream, { mimeType: finalMimeType });

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        recordedChunksRef.current.push(e.data);
      }
    };

    // When recording stops, create the final blob and download
    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunksRef.current, { type: finalMimeType });
      const url = URL.createObjectURL(blob);

      // Choose file extension based on our final mime type
      let extension = 'webm';
      if (finalMimeType.includes('mp4')) {
        extension = 'mp4';
      }

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `yearly-interview.${extension}`;
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      recordedChunksRef.current = [];
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setRecording(true);

    // Show the first question overlay briefly
    showCurrentQuestion();
  };

  const stopRecording = () => {
    if (!mediaRecorderRef.current) return;
    if (mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    setRecording(false);
  };

  // Show the current question text overlay for a few seconds
  const showCurrentQuestion = () => {
    setShowQuestion(true);
    setTimeout(() => {
      setShowQuestion(false);
    }, 3000);
  };

  // Proceed to next question
  const handleNextQuestion = () => {
    if (currentQuestionIndex < currentQuestions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      // show next question overlay
      setTimeout(() => {
        showCurrentQuestion();
      }, 500);
    } else {
      // If last question, stop
      stopRecording();
    }
  };

  return (
    <div className="yearly-interview">
      <div className="interview-container">
        <div className="left-section">
          <header className="interview-header">
            <h1>{currentTranslations.title}</h1>
            <p className="subtitle">{currentTranslations.subtitle}</p>
          </header>
          
          {recording && (
            <div className="recording-indicator">
              <div className="recording-dot"></div>
              <span className="recording-text">{currentTranslations.recording}</span>
            </div>
          )}

          <div className="question-status">
            <div className="progress">
              <span className="question-counter">
                {currentTranslations.questionCount} {currentQuestionIndex + 1} {currentTranslations.of} {currentQuestions.length}
              </span>
              <div className="progress-bar">
                <div 
                  className="progress-fill" 
                  style={{width: `${((currentQuestionIndex + 1) / currentQuestions.length) * 100}%`}}
                />
              </div>
            </div>
            <p className="current-question">
              {currentQuestions[currentQuestionIndex]}
            </p>
          </div>

          <div className="controls">
            {!recording ? (
              <button className="primary-button" onClick={startRecording}>
                {currentTranslations.startButton}
              </button>
            ) : (
              <div className="button-group">
                <button className="secondary-button" onClick={stopRecording}>
                  {currentTranslations.finishButton}
                </button>
                <button 
                  className="primary-button"
                  onClick={handleNextQuestion} 
                  disabled={!recording}
                >
                  {currentQuestionIndex < currentQuestions.length - 1 
                    ? currentTranslations.nextButton 
                    : currentTranslations.completeButton}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="right-section">
          <video ref={videoRef} style={{ display: 'none' }} muted playsInline />
          
          <div className="canvas-container">
            <canvas ref={canvasRef} width={640} height={480} />
          </div>
        </div>
      </div>
    </div>
  );
}
