import React, { useState } from 'react';
import './AuthorSelectDialog.css';

export default function AuthorSelectDialog({ 
  isOpen, 
  onClose, 
  authors, 
  onAuthorSelect,
  verses 
}) {
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showVerses, setShowVerses] = useState(false);

  const handleClose = () => {
    setSelectedAuthor(null);
    setShowConfirm(false);
    setShowVerses(false);
    onClose();
  };

  if (!isOpen) return null;

  const handleAuthorClick = (author) => {
    setSelectedAuthor(author);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    setShowVerses(true);
  };

  const handleBack = () => {
    setSelectedAuthor(null);
    setShowConfirm(false);
    setShowVerses(false);
  };

  const authorVerses = verses.filter(verse => verse.author === selectedAuthor);
  const versesWithPrevious = authorVerses.map((verse, idx) => {
    const previousVerse = verses.find((v, i) => 
      verses[i + 1]?.firstLine === verse.firstLine
    );
    return {
      ...verse,
      previousLine: previousVerse?.secondLine
    };
  });

  return (
    <div className="author-dialog-overlay">
      <div className="author-dialog">
        {!showConfirm && !showVerses ? (
          <>
            <h2>Кто ты из этих прекрасных людей? 🤔</h2>
            <div className="authors-list">
              {authors.map((author, index) => (
                <button
                  key={index}
                  className="author-button"
                  onClick={() => handleAuthorClick(author)}
                >
                  {author}
                </button>
              ))}
            </div>
            <button className="cancel-button" onClick={handleClose}>
              Я передумал(а)
            </button>
          </>
        ) : showConfirm ? (
          <div className="confirm-screen">
            <h2>Это точно ты? 🧐</h2>
            <p className="selected-author">{selectedAuthor}</p>
            <div className="confirm-buttons">
              <button onClick={handleConfirm}>Да!</button>
              <button onClick={handleConfirm}>Точно да!</button>
            </div>
            <button className="cancel-button" onClick={handleBack}>
              Ой, не я
            </button>
          </div>
        ) : (
          <div className="verses-screen">
            <h2>Твои строчки:</h2>
            <div className="verses-list">
              {versesWithPrevious.map((verse, idx) => (
                <div key={idx} className="verse">
                  {verse.previousLine && (
                    <p className="previous-line">
                      Предыдущая строка: "{verse.previousLine}"
                    </p>
                  )}
                  <div className="verse-pair">
                    <p>{verse.firstLine}</p>
                    <p>{verse.secondLine}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="dialog-buttons">
              <button onClick={handleBack}>Назад</button>
              <button onClick={handleClose}>Жесткий пон!</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 